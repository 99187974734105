import React, { Component } from "react";
import MenuItem from "../Components/MenuItem";
import MenuOptions from "../utils/MenuOptions";

import "../styles/Menu.css";
import { Link } from "react-router-dom";

export default class Menu extends Component {
    constructor() {
        super();

        this.state = {
            menuOptions: [],
        };
    }

    componentDidMount() {
        this.setState({
            menuOptions: MenuOptions,
        });
    }

    render() {
        return (
            <div className='MainMenu'>
                <div className='MainMenu__container'>
                    {this.state.menuOptions.map((option) => (
                        <Link to={option.toRoute} className='MainMenu__a'>
                            <MenuItem
                                key={option.id}
                                title={option.title}
                                icon={option.icon}
                                description={option.description}
                                toRoute={option.toRoute}
                            />
                        </Link>
                    ))}
                </div>
            </div>
        );
    }
}
