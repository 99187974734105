import React, { Component } from "react";
import { googleLogout } from "@react-oauth/google";
import AppContext from "../context/AppContext";
import { Link } from "react-router-dom";

import MenuOptions from "../utils/MenuOptions";
import logoutIcon from "../Assets/img/logout.png";
import closeIcon from "../Assets/img/close.png";
import "../styles/Navbar.css";

export default class Navbar extends Component {
    constructor() {
        super();

        this.state = {
            menuOptions: [],
        };

        this.logout = this.logout.bind(this);
        this.showMenu = this.showMenu.bind(this);
    }

    static contextType = AppContext;

    async logout() {
        googleLogout();
        await cookieStore.set("loggedUser", null);
        this.context.setUser(null);
        location.replace("/login");
    }

    showMenu() {
        document.querySelector(".Profile__card").classList.toggle("Show");
    }

    componentDidMount() {
        this.setState({
            menuOptions: MenuOptions,
        });
    }

    render() {
        return (
            <nav className='Navbar'>
                <div className='Navbar__menu'></div>
                <div className='Navbar__profile'>
                    <div className='Menu'>
                        <p>{this.context.state.user.name}</p>
                        <img
                            className='Menu__toggle'
                            src={this.context.state.user.picture}
                            alt={this.context.state.user.name}
                            onClick={() => this.showMenu()}
                        />
                    </div>
                    <div className='Profile__card'>
                        <div className='Card__title'>
                            <p>{this.context.state.user.email}</p>
                            <img
                                src={closeIcon}
                                alt='Cerrar'
                                onClick={() => this.showMenu()}
                            />
                        </div>
                        <div className='Card__content'>
                            <img src={this.context.state.user.picture} />
                            <h4>
                                ¡Hola, {this.context.state.user.given_name}!
                            </h4>
                            <div className='Card__footer'>
                                <div className='Card__menu'>
                                    <div className='MenuItem'>
                                        <Link to={"/"}>Inicio</Link>
                                    </div>
                                    {this.state.menuOptions.map((option) => (
                                        <div
                                            className='MenuItem'
                                            key={option.id}>
                                            <Link to={option.toRoute}>
                                                {option.title}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className='Logout'
                                    onClick={() => this.logout()}>
                                    <img src={logoutIcon} alt='Cerrar sesion' />
                                    <span>Cerrar sesión</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}
