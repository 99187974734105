import { useState } from "react";

const initialState = {
    user: null
};

const useInitialState = () => {
    const [state, setState] = useState(initialState);

    const setUser = (payload) => {
        setState({
            ...state,
            user: payload
        });
    }

    return {
        state,
        setUser
    }
}

export default useInitialState;