import React from "react";

import "../styles/MenuItem.css";

const MenuItem = ({ title, icon, description, toRoute }) => {
    const redirectTo = (evt) => {
        if (!evt.target.matches(".MenuItem")) {
            console.log(toRoute);
            location.replace(toRoute);
        }
    };

    return (
        <div className='MenuItem'>
            <div className='MenuItem__title'>
                <img src={icon} alt={title} />
                <span>{title}</span>
            </div>
            <div className='MenuItem__description'>
                <strong>{title}</strong>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default MenuItem;
