import React from "react";

import Menu from "../Containers/Menu";

const Home = () => {
    return (
        <div>
            <Menu />
        </div>
    );
};

export default Home;
