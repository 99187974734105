import React, { Component } from "react";

import "../styles/Header.css";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

export default class Header extends Component {
    render() {
        return (
            <header className='Header'>
                <div>
                    <img
                        className='Header__logo'
                        src={process.env.REACT_APP_LOGO2_URL}
                        alt='Logo2'
                    />
                    <Link to={"/"}>
                        <h3 className='Header__title'>
                            {process.env.REACT_APP_HEADER}
                        </h3>
                    </Link>
                </div>
                <div>
                    <Navbar />
                </div>
            </header>
        );
    }
}
