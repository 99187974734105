import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookie from "js-cookie";

import endpoints from "../Services/Api/endpoints";

import AppContext from "../context/AppContext";

import googleIcon from "../Assets/img/google-icon.png";
import "../styles/Login.css";

const Login = () => {
    const { state, setUser } = useContext(AppContext);

    const getUserInfo = (response) => {
        axios
            .get(
                endpoints.google.userinfo +
                    `?access_token=${response.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                        Accept: "application/json",
                    },
                }
            )
            .then(async (response) => {
                let encryptedUser = CryptoJS.AES.encrypt(
                    JSON.stringify(response.data),
                    process.env.REACT_APP_KEY
                ).toString();
                Cookie.set("loggedUser", encryptedUser, {
                    expires: 5,
                });
                setUser(response.data);
                location.replace("/");
            })
            .catch((error) => console.error(error));
    };

    const login = useGoogleLogin({
        onSuccess: (response) => getUserInfo(response),
        onError: (error) => console.log("Login failed:", error),
    });

    if (state.user != null) {
        return <Navigate replace to={"/"} />;
    } else {
        return (
            <div className='Login'>
                <div className='Login__container'>
                    <img src={process.env.REACT_APP_LOGO_URL} alt='Logo' />
                    <h1>Bienvenidos</h1>
                    <h4>{process.env.REACT_APP_NAME}</h4>
                    <button type='button' onClick={() => login()}>
                        <img src={googleIcon} alt='Google icon' />
                        <span>Iniciar Sesión</span>
                    </button>
                </div>
            </div>
        );
    }
};

export default Login;
