import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "../Containers/Layout";
import Home from "../Pages/Home";
import AppContext from "../context/AppContext";
import useInitialState from "../hooks/useInitialState";
import Login from "../Pages/Login";
import "../styles/default.css";

const App = () => {
    const initialState = useInitialState();
    return (
        <AppContext.Provider value={initialState}>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/' element={<Home />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </AppContext.Provider>
    );
};

export default App;
