import React, { Component } from "react";
import CryptoJS from "crypto-js";
import Cookie from "js-cookie";

import AppContext from "../context/AppContext";
import Login from "../Pages/Login";
import Header from "../Components/Header";

export default class Layout extends Component {
    static contextType = AppContext;

    componentDidMount() {
        let encryptedUser = Cookie.get("loggedUser");
        if (
            encryptedUser !== null &&
            encryptedUser !== "null" &&
            encryptedUser != undefined
        ) {
            let bytesUser = CryptoJS.AES.decrypt(
                encryptedUser,
                process.env.REACT_APP_KEY
            );
            let user = JSON.parse(bytesUser.toString(CryptoJS.enc.Utf8));
            this.context.setUser(user);
        }
    }

    render() {
        return this.context.state.user ? (
            <div>
                <Header />
                <main>{this.props.children}</main>
            </div>
        ) : (
            <Login />
        );
    }
}
