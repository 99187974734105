import React from "react";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";

import App from "./Routes/App";

const appContainer = document.getElementById("app");
const root = createRoot(appContainer);

root.render(
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`} >
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </GoogleOAuthProvider>
);