import searchIcon from "../Assets/icons/search_blue.png";
import searchDistributorIcon from "../Assets/icons/search_distributor.png";
import sharedFolderIcon from "../Assets/icons/shared_folder.png";
import tableIcon from "../Assets/icons/table.png";
import formIcon from "../Assets/icons/form.png";
import linkIcon from "../Assets/icons/link.png";

const MenuOptions = [
    {
        id: 1,
        title: "Buscador general",
        icon: searchIcon,
        description:
            "En este apartado es donde se hacen las búsquedas de productos de diferentes mayoristas, el buscador solo recopila los datos de los sitios web de los mayoristas preferentes por la empresa ATI, al darle clic de en uno de los archivos mostrados, este redirecciona a la página del producto específicamente donde se encuentra el producto.",
        toRoute: "/buscador-general",
    },
    {
        id: 2,
        title: "Buscador Mayoristas",
        icon: searchDistributorIcon,
        description:
            "En este apartado se hacen las consultas de los productos de los diferentes mayoristas de manera independiente, donde se pueden buscar productos disponibles mostrando el modelo, precios y una descripción grafica del producto.",
        toRoute: "/buscador-mayoristas",
    },
    {
        id: 3,
        title: "Carpeta compartida",
        icon: sharedFolderIcon,
        description:
            "En este apartado cuenta con la funcionalidad de mostrar archivos compartidos de las tablas compartidas en formato PDF, trae la opción de iniciar sesión con Google y en seguida muestra los archivos que se compartió a nombre de la cuenta.",
        toRoute: "/carpeta-compartida",
    },
    {
        id: 4,
        title: "Tablas comparativas",
        icon: tableIcon,
        description:
            "Esta pestaña, contiene la funcionalidad de realizar las comparativas de diferentes catálogos de productos por ejemplo pantallas, tables, laptops, monitores, impresoras, comparando las principales características de cada producto o modelo, donde en la tabla se especifica cual es superior, al ofertado o si tienen características en común, además de eso tiene la funcionalidad de compartir el documento en Google Drive o simplemente descargarlo en formato pdf.",
        toRoute: "/comparativas",
    },
    {
        id: 5,
        title: "Registros y Formularios",
        icon: formIcon,
        description:
            "En este apartado es donde se hace el registro de licitaciones mediante un formulario donde cuenta con la funcionalidad de modificar los campos dependiendo del requerimiento, y los estatus de cada licitación por ejemplo si se ganó o si se perdió, a parte de lo de sus estatus.",
        toRoute: "/registros-formularios",
    },
    {
        id: 6,
        title: "Bodega de links",
        icon: linkIcon,
        description:
            "En este apartado contiene la funcionalidad de agregar links de proveedores de equipos tecnológicos o de marcas, donde los links redirigen a ciertos sitios donde contienen información de la misma marca, pero con contenido especifico. Dentro de esta ventana hay un apartado del administrador donde prácticamente se lleva el control del sistema, en este apartado se pueden agregar usuarios de quienes pueden podrán acceder al sistema, registro de los links del buscador general, registro de links de las marcas y la funcionalidad de desactivar los links de proveedores y de marcas.",
        toRoute: "/links",
    },
];

export default MenuOptions;